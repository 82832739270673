.zoomed-word-cloud-modal {
    height: 100%;
    .modal-header {
        height: 10%;
    }
    .modal-body {
        height: 85%;
    }
    .modal-footer {
        height: 5%;
    }
    .smvp-word-cloud-wrapper {
        height: 100%;
    }
}
