.modal-wrapper.dialog {
    .modal-footer {
        border-top: none;
        padding-top: 0;
    }

    &.center {
        text-align: center;

        .modal-footer {
            text-align: center;
        }
    }
}
