@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

// @see src/angular/directives/directives/strapModal.js hideBackdrop function
.modal.fade {
    display: none;
}

// Background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z-index(modal-backdrop);
    background: color(base-700);
    display: none;
    opacity: 0;
    transition: $base-opacity-transition;
}

// Base modal (legacy modals)
.modal {
    position: fixed;
    left: 50%;
    z-index: z-index(modal);
    max-height: $modal-base-max-height;
    width: $modal-width-small;
    margin-left: math.div($modal-width-small, -2);
    display: none;
    outline: none;
    background-color: color(base-000);
    border-radius: $base-border-radius;
    opacity: 0;
    transition: $base-opacity-transition;

    top: 50%;
    transform: translateY(-50%);
    box-shadow: $modal-box-shadow;

    &.xl-modal {
        width: $modal-width-large;
        margin-left: math.div($modal-width-large, -2);
    }

    .modal-body {
        max-height: $modal-body-max-height;
    }
}

body.modal-open {
    .modal-backdrop {
        display: block;
        opacity: $modal-backdrop-opacity;
    }

    .modal {
        &.in {
            display: block;
            opacity: 1;
        }
    }
}
