// Abstracts
@use "abstracts/index" as *;

@include font-face(
    '#{$icons-font-family}',
    font-files(
        '#{$icons-font-family}.woff' 'woff',
        '#{$icons-font-family}.ttf' 'truetype',
        '#{$icons-font-family}.svg' 'svg'
    ),
    400
);

:root {
    --container-max-columns: #{$grid-columns};
    --grid-vertical-gap: #{box-model(l)};
}

// Titles
// ------

h1, h2, h4, h5, h6, strong {
    @include font-weight(semibold);
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    word-wrap: break-word;

    small {
        margin-left: box-model(m);
    }
}

h5, h6 {
    margin: 0;
    padding: 0;
}

h1 {
    @include font-size(xxm);
    @include line-height(xxm);
    color: color(base-700);
    margin-bottom: box-model(l);

    & + p.subtitle {
        margin-top: box-model(-l) + box-model(xxs);
    }

    .badge {
        vertical-align: middle;
        margin-left: box-model(m);
        margin-top: box-model(-xxs);
    }
}

h2 {
    @include font-size(xm);
    @include line-height(m);
    margin-bottom: box-model(m);

    & + p.subtitle {
        margin-top: box-model(-l) + box-model(xxs);
    }
}

%h3 {
    @include font-size(xm);
    @include font-weight(normal);
    @include line-height(base);
    border-bottom: 1px solid color(base-500);
    margin-bottom: box-model(m);
    padding-bottom: box-model(xs);
}

h3 {
    @extend %h3;
}

h4 {
    @include font-size(s);
    @include line-height(xxs);
    margin-bottom: box-model(xs);
    padding: 0;

    &.separator {
        border-bottom: 1px solid color(base-300);
        margin-bottom: box-model(l);
        padding-bottom: box-model(xs);
    }

    a:hover {
        text-decoration: underline;
    }
}

h5 {
    @include font-size(base);
    @include line-height(base);
}

h6 {
    @include font-size(s);
    @include line-height(xxs);
}

// Texts
// -----

span.highlight {
    @include font-weight(semibold);
}

p {
    margin: 0 0 box-model(m);

    &.subtitle {
        @include line-height(base);
        color: color(base-500);
    }

    &:only-child {
        margin-bottom: 0;
    }

    &:empty {
        margin: 0;
    }
}

hr {
    background: none;
    border: 0;
    border-top: 1px solid color(base-300);
}

// Links
// -----

a {
    color: color(primary-300);
    text-decoration: none;

    &:hover {
        color: color(primary-500);
        text-decoration: none;
    }

    &[disabled],
    &.disabled {
        opacity: .6;
        cursor: not-allowed
    }
}

a[href],
.has-action {
    cursor: pointer;
}

a.opposite,
span.opposite,
small.opposite,
div.opposite {
    float: right;

    &:dir(rtl) {
        float: left;
    }
}

img {
    transition: $base-opacity-transition;
    max-width: 100%;
}

figure {
    margin: 0;
    font-size: 0;
    line-height: 0;

    picture {
        display: inline-block;
        max-width: 100%;

        img {
            min-width: 100%;
        }
    }

    figcaption {
        @include font-size(base);
        @include line-height(base);
    }
}

// Utils
// -----

abbr {
    white-space: nowrap;
}

small, .small:not(div) {
    @include font-size(s);
}

small:not(.important) {
    color: color(base-500);
}

.clearfix {
    @include clearfix;
}

.hidden, .hide {
    display: none !important;
}

footer.actions {
    text-align: right;
}

header a.back {
    @include font-size(s);
    @include line-height(xxs);
    margin-bottom: box-model(xs);

    color: color(base-500);
    display: inline-block;

    &:hover {
        opacity: .8;
    }

    i {
        margin-right: box-model(xs);
    }
}

.success {
    color: color(success-300);
}

.warning {
    color: color(warning-300);
}

.error {
    color: color(error-300);
}

.boxed-content {
    border: 1px solid color(base-300);
    background-color: color(base-050);
    border-radius: box-model(xs);
    padding: box-model(m);
    margin-bottom: box-model(m);
}

.or {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin: box-model(l);
    gap: box-model(s);

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid color(base-300);
    }
}

.drag-handle {
    cursor: grab;
}

// Fullscreen mode
// ---------------

:fullscreen {

    background-color: color(base-000);

    #privileged-content-wrapper {
        margin-left: 0;
    }

    nav.main, .fullscreen-controls-container {
        display: none !important;
    }
}
