// Abstracts
@use "abstracts/index" as *;

[class^='ic-'], [class*=' ic-'] {
    @extend %icon;
}

@each $icon in map-keys($icons) {
    .#{$icon} {
        @include icon($icon);
    }
}

.icon-wrapper {
    display: inline-block;
    height: icon-size(xs);
    width: icon-size(xs);
    border-radius: $base-border-radius;
    vertical-align: middle;
    margin-right: box-model(xs);
    text-align: center;
    background-color: color(base-100);

    &.alt-1 {
        color: color(success-500);
        background-color: color(success-100);
    }

    &.alt-2 {
        color: color(error-500);
        background-color: color(error-100);
    }

    &.alt-3 {
        color: color(warning-500);
        background-color: color(warning-100);
    }

    &.alt-4 {
        color: color(primary-500);
        background-color: color(primary-100);
    }

    i {
        @include font-size(document);
        @include line-height(base);
    }
}

th, td {
    i {
        vertical-align: middle;
    }
}
