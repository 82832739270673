@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

body .v--modal {
    border-radius: $base-border-radius * 2;
    box-shadow: $modal-box-shadow;
    background-color: color(base-000);
}

.v--modal-overlay, .v--modal-box {
    position: fixed !important;

    // TODO: remove this when we find another way to attach the dropdown menu to the body.
    // As it is now, the dropdown is misplaced on small screens (you can check on the Studio
    // registration page settings)
    overflow: unset !important;
}

.v--modal-overlay {
    background: colorAlpha(base-700, $modal-backdrop-opacity);

    &:has(~ .v--modal-overlay) {
        background: transparent;

        .v--modal-box {
            transform: translate(0, -45%) scale(0.95);
        }
    }
}

.v--modal-box {
    background-color: color(base-000);
    padding: 0;
    box-shadow: $modal-box-shadow;
    max-height: $modal-base-max-height;
    border-radius: $base-border-radius * 2;
    transform: translateY(-50%);

    // Following !importants due to inline styles
    top: 50% !important;
    left: 50% !important;
    width: $modal-width-small !important;
    margin-left: math.div($modal-width-small, -2) !important;
    transition: $modals-width-transition;

    &.medium {
        width: $modal-width-medium !important;
        margin-left: math.div($modal-width-medium, -2) !important;
    }

    &.large {
        width: $modal-width-large !important;
        margin-left: math.div($modal-width-large, -2) !important;
    }

    &.transparent {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    p {
        @include line-height(xs);
    }
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    max-height: $modal-base-max-height;

    .modal-header {
        flex: 1 1 10%;
    }

    .modal-body {
        flex: 1 1 85%;
        overflow: auto;
    }

    .modal-footer {
        flex: 1 1 5%;
    }
}

.modal-header {
    padding: box-model(l, xxl, m);

    h3 {
        @include font-weight(semibold);

        border: 0;
        padding: 0;
        margin: 0;
    }
}

.modal-body {
    box-sizing: border-box;
    padding: box-model(l);
    padding-left: box-model(xxl);
    overflow: auto;
    scrollbar-gutter: stable;

    &.narrow {
        padding-left: box-model(h) * 2; // Very special case
        padding-right: box-model(h) * 2; // Very special case
    }

    .alert:last-child {
        margin-top: box-model(m);
        margin-bottom: 0;
    }

    .meta-form:last-child {
        margin-bottom: 0;
    }
}

.modal-header + .modal-body {
    padding-top: 1px;
}

.modal-footer {
    padding: box-model(l, xxl);
    border-top: 1px solid color(base-100);
    text-align: right;

    > .btn {
        + .btn {
            margin-left: box-model(xs);
        }
    }

    .contextual, .counter {
        float: left;
        display: flex;
        text-align: left;
    }

    .counter {
        .count {
            @include font-size(l);
            @include line-height(xxm);

            color: color(base-500);
            margin-right: box-model(xs);

            &.active {
                color: color(primary-300);
            }
        }

        .type {
            @include font-size(base);
            @include font-weight(bold);
            @include line-height(xxs);

            color: color(base-700);
        }

        span {
            @include font-size(s);
            @include line-height(xxxs);

            color: color(base-500);
            display: block;
        }
    }
}

.v--modal-overlay .v--modal-background-click {
    background-color: colorAlpha(base-700, 0.35);

    .v--modal-overlay,
    .v--modal-background-click {
        background-color: transparent
    }
}
