@use "sass:math";
@use "../functions" as *;
@use "./base" as *;

// Main menu
// ---------

$main-menu-width: box-model(xs) * 40;
$main-menu-minimized-width: 72px;
$main-menu-user-menu-size: box-model(xxl);
$main-menu-toggler-size: box-model(l);

// Third level menu
// ----------------
$submenu-width: box-model(xs) * 40;

// Border radiuses
// ---------------

$small-border-radius: math.div(box-model(xxs), 2);
$base-border-radius: box-model(xxs);
$large-border-radius: box-model(xs);
$extra-large-border-radius: box-model(m);

// Miscellaneous
// -------------

$base-spinner-size: icon-size(xxl);
$base-box-shadow: 0 box-model(xxs) box-model(xs) 0 rgba(0, 0, 0, 0.1);

// Forms
// -----

$input-height: box-model(xl);
$search-input-min-width: box-model(xs) * 29;
$form-check-input-gutter: box-model(m);
$form-check-input-margin-y: 3px;

// Buttons
// -------

$button-max-height: box-model(xl);
$close-button-size: box-model(xl);
$large-button-height: box-model(xxl);  // 40
$indirect-button-heights: box-model(xl);
$large-button-width: box-model(xs) * 11;  // 88

// Alerts
// ------

$alert-thick-border-size: 6px;

// Horizontal Navs
// ---------------

$indirect-tabnav-menu-height: box-model(xxl);
$nav-item-horizontal-margin-right: box-model(xl);
$nav-item-horizontal-margin-bottom: box-model(xs);
$nav-item-vertical-margin: box-model(l);

// Tooltips
// --------

$tooltips-max-width: 256px;
$tooltips-delay-time: .1s;

// Dropdowns
// ---------

$dropdowns-min-width: 232px;
$dropdowns-max-width: 320px;
$dropdowns-side-paddings: 10px;
$dropdown-activator-border-radius: box-model(xxxs);
$dropdown-menu-max-height: 232px;

// Wizard
// ------

$wizard-steps-size: box-model(l);
$wizard-steps-max-width: 760px;
$wizard-header-height: box-model(xs) * 24;

// Modals
// ------

$modal-width-small: 584px;
$modal-width-medium: 848px;
$modal-width-large: 1024px;
$modal-viewport-offset: box-model(xs) * 10;
$modal-header-height: box-model(l) + box-model(m) + box-model(l);
$modal-footer-height: box-model(xxxl) + box-model(xl) + 1px; // 1px = footer border size
$modal-base-max-height: calc(100vh - #{$modal-viewport-offset});
$modal-body-max-height: calc(100vh - #{$modal-header-height + $modal-footer-height + $modal-viewport-offset});
$modal-body-wizard-max-height: calc(100vh - #{$wizard-header-height + $modal-footer-height + $modal-viewport-offset});
$modal-backdrop-opacity: 0.35;
$modal-box-shadow: -2px 4px 12px 0 rgba(0, 0, 0, 0.05);
$modal-html-editor-min-height: box-model(xs) * 23;

// `no-flex` boxes sizes
// ---------------------

$carousel-main-media-max-height: 370px;

// Notifications
// -------------

$notification-width: 320px;
$notification-offset-x: box-model(xl);
$notification-offset-y: 90px;
$notification-icon-size: 24px;

// Color picker
// ------------

$color-picker-size: box-model(m);
$color-picker-container-width: 235px;
$color-picker-dragger-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
$color-picker-slider-box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);
$color-picker-slider-border-radius: 2px;

// Datepickers
// -----------

$date-picker-cell-size: 24px;
$date-picker-min-width: box-model(xs) * 23; // 184px when box-model(xs) = 8px

// Cards
// -----

$cards-tile-width: box-model(xs) * 29;  // 232px when box-model(xs) = 8px
$cards-tile-image-height: box-model(xs) * 19; // 152px when box-model(xs) = 8px
$cards-tile-image-ratio: percentage(math.div($cards-tile-image-height, $cards-tile-width));
$cards-dashboard-metrics-height: 420px;

// Blocking messages
// -----------------

$blocking-message-container-width: box-model(xs) * 95;

// Live Stream components
// -----------------------

$live-stream-sidebar-width: box-model(xs) * 38;

// Studio components
// -----------------
$invitation-container-width: box-model(xs) * 59;

// Other widgets
// -------------

$checklist-side-margin: box-model(xl);
$checklist-width: (box-model(xl) * 10) - 2px; // 2px = side borders
$checklist-shadow: -2px 4px 12px 0 rgba(0, 0, 0, 0.05);
$checklist-max-height: 60vh;
$checklist-collapsed-position: 8px box-model(xxl);
$checklist-expanded-position: 32px 16px;
$checklist-animation-timing-function: cubic-bezier(0.64, 0.04, 0.35, 1);
$checklist-counters-transition:
    top .4s $checklist-animation-timing-function,
    right .4s $checklist-animation-timing-function;

$checklist-details-transition: max-height .4s $checklist-animation-timing-function;

// Miscellaneous
// -------------

$null-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
$notifications-box-shadow: 2px box-model(xxs, xxs) 0 rgba(0, 0, 0, 0.1);
$modal-box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.05);
$date-picker-box-shadow: $base-box-shadow;
$color-picker-box-shadow: $base-box-shadow;
$page-content-distance-from-top: 104px;
$stream-controls-height: 42px;
$login-box-shadow: 0 0 box-model(m) rgba(0, 0, 0, 0.15);

// Transitions
// -----------

$carousel-buttons-opacity-transition: opacity .1s ease-in-out;
$main-menu-collapse-transition: width .2s ease-in-out;
$main-content-menu-collapse-transition: margin-left .2s ease-in-out;
$common-toolbar-menu-collapse-transition: left .2s ease-in-out;
$modals-width-transition: $base-width-transition, $base-transform-transition, margin-left .1s ease-in-out;
$togglers-transition: left .1s linear, background-color .1s linear;
$cta-contextual-info-transition: max-height .2s ease;

// Progress bars
// -------------

$progress-bar-height: 6px;


// Editors
// -------

$html-editor-max-height: box-model(xs) * 52;

// Uploader
// --------

$uploader-box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.1);

// Event Dashboard
// ---------------

$event-dashboard-max-title-width: 629px;
