// Typography
// ----------

$base-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Lucida Grande", "Segoe UI";
$secondary-font: Consolas, monospace;

$font-sizes: (
    xxs:      8px,
    xs:       10px,
    s:        12px,
    base:     14px,
    document: 16px,
    m:        18px,
    xm:       20px,
    xxm:      24px,
    l:        32px,
    xl:       36px,
    xxl:      40px,
    xxxl:     48px,
    h:        64px,
);

$font-weights: (
    bold: 700,
    semibold: 600,
    normal: 400,
    thin: 100
);

$line-heights: (
    xxxs: 14px,
    document: 16px,
    xxs: 18px,
    xs: 20px,
    s: 22px,
    base: 24px,
    m: 28px,
    xm: 30px,
    xxm: 32px,
    l: 40px,
    xl: 42px,
    xxl: 48px,
    r: 60px,
    xr: 64px,
    xxr: 96px,
);

$letter-spacings: (
    l: 2px,
);
