// Abstracts
@use "abstracts/index" as *;

.definition-doc {
    .modal-header {
        h3 {
            border-bottom: 1px solid color(base-500);
            padding-bottom: box-model(xs);
            margin: box-model(m, 0);
        }
    }
}
