@keyframes fade {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
