@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.recommended-settings-modal{
    display: flex;
    flex-direction: column;
    max-height: $modal-base-max-height;
    .header {
        padding: box-model(l) box-model(xxl);

        h2 {
            margin-bottom: 0;
        }
    }
    .body {
        padding: box-model(l) box-model(xxl);
        padding-top: 0;
        overflow: auto;
        flex: 1 1 auto;

        .alert-warning {
            @include font-weight(semibold);
        }

        .accordion-settings {
            border: 1px solid color(base-300);
            border-radius: box-model(m);
            transition: border-color .15s ease-out;

            .name-text {
                @include font-weight(semibold);
                flex-grow: 1;
                display: flex;
                align-items: center;
                padding: box-model(s) box-model(m);
            }

            .title {
                @include font-weight(semibold);
            }

            &.open {
                border-color: color(primary-300);
                .toggler {
                    color: color(primary-300);
                }
                section {
                    display: flex;
                    padding: 0 box-model(m);
                    gap: box-model(xs);
                    .col-1, .col-2 {
                        @include font-size(s);
                        flex: 1;

                        span {
                            display: inline-block;
                            padding-bottom: box-model(s);
                        }

                        span:has(+ span) {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        .know-more-link {
            padding: box-model(l) 0;
        }

        .endpoints {
            padding-top: box-model(l);
            border-top: 1px solid color(base-300);

            .input-urls {
                display: flex;
                align-items: center;
                gap: box-model(xs);
                margin-bottom: box-model(l);

                .base-url {
                    flex: 1;
                    padding: box-model(xxs)*1.5 box-model(s);
                    margin: 0;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                }
            }
        }

    }

    .footer {
        border-top: 1px solid color(base-300);
        padding: box-model(l) box-model(xxl);
        display: flex;
        justify-content: flex-end
    }
}
