// Abstracts
@use "abstracts/index" as *;

.mailing-log-details-modal {
    section {
        margin-bottom: box-model(m);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .mail-logs-details {
        .table-wrapper {
            margin-bottom: box-model(m);

            &:last-child {
                margin-bottom: 0;
            }
        }

        th {
            text-align: right;
        }
    }

    .smpt-event {
        margin-bottom: box-model(m);

        &:last-child {
            margin-bottom: 0;
        }
    }

    iframe {
        width: 100%;
        border: 0;
        height: 80vh;
        border-radius: $small-border-radius;
    }

    .expand-details {
        border-color: color(error-300);

        .table-wrapper {
            border-color: color(error-300);
        }
    }

}
