// Abstracts
@use "abstracts/index" as *;
@use "default/components/modals/base" as *;

.v--modal.vue-dialog {
    .dialog-content {
        @extend .modal-body;
        padding: box-model(l) !important;
    }

    .dialog-c-title {
        @extend h2 !optional;
        margin-bottom: 0;
    }

    .vue-dialog-buttons {
        @extend .modal-footer;
        display: block;
        width: auto;
        text-align: right;
    }
}
