// Abstracts
@use "abstracts/index" as *;

.row-fluid.table {
    .total-records-line {
        display: none;
    }
}

table {
    border-collapse: collapse;
    min-width: 100%;

    td.ellipsis {
        position: relative;

        &:before {
            content: '&nbsp;';
            visibility: hidden;
        }
        > span, > a {
            &:not(.badge) {
                position: absolute;
                left: box-model(s);
                right: box-model(s);
                @include ellipsis(true);
            }
        }
    }

    th, td {
        padding: box-model(xs, s);

        &.large-text-ellipsis {
            @include ellipsis(false);
            max-width: box-model(xs) * 62; // ~496px when box-model(xs) is 8px
        }

        &.checker {
            width: box-model(xxl);
            text-align: center;
            padding-right: 0;
        }

        &.selector {
            input {
                vertical-align: middle;
            }
        }

        &.narrow {
            width: box-model(xl);
        }

        &.wide {
            min-width: 40%;
        }
    }

    > thead {
        > tr {
            > th, > td {
                background-color: color(base-300);
                color: color(base-700);
            }

            > th {
                @include ellipsis;
                @include font-weight(semibold);

                text-align: left;

                &:dir(rtl) {
                    text-align: right;
                }
            }
        }
    }

    &.table-striped {
        > tbody > tr {
            &:nth-child(even) {
                > th, > td {
                    background-color: color(base-050);
                }
            }
            &:nth-child(odd) {
                > th, > td {
                    background-color: color(base-000);
                }
            }
        }
    }

    &.table-striped-double {
        > tbody > tr {
            &:nth-child(2n) {
                > th, > td {
                    padding-top: box-model(xxs);;
                    border-bottom: 1px solid color(base-300);
                }
            }

            &:nth-child(2n+1) {
                > th, > td{
                    padding-bottom: box-model(xxs);
                }
            }

            &:nth-child(4n), &:nth-child(4n-1) {
                background-color: color(base-050);

                > th, > td {
                    background-color: color(base-050);
                }
            }
        }
    }

    &.actionable {
        > tbody > tr {
            cursor: pointer;
            &:nth-child(odd) {
                border-bottom: 1px solid color(base-000);
                border-top: 1px solid color(base-000);
            }

            &.invalid {
                cursor: default;
            }

            &.selected {
                > td {
                    background-color: color(primary-050);
                }
            }
        }

        > tbody > tr:hover {
            @each $s in even, odd {
                &:nth-child(#{$s}) {
                    > th, > td {
                        background-color: color(base-100);
                    }
                }
            }
        }
    }

    &.table-fixed {
        table-layout: fixed;
    }

    &.mini {
        @include font-size(xs);
        @include line-height(xs);

        th, td {
            padding: box-model(xxs, xs);
        }
    }

    &.compact {
        @include line-height(xs);

        th, td {
            padding: box-model(xxs, xs);
        }
    }

    > tbody {
        > tr > th {
            text-align: left;
        }

        > tr > td {
            > *:last-child {
                margin-bottom: 0;
            }
        }

        > tr.invalid {
            opacity: .5;

            > td:not(.actions) {
                text-decoration: line-through;
            }
        }

        > tr.even {
            th, td {
                background-color: color(base-050);
            }
        }

        > tr.odd {
            th, td {
                background-color: color(base-000);
            }
        }
    }

    td > table {
        margin: box-model(xs) * -1 box-model(s) * -1;

        &, thead, tbody, tfoot, tr, th, td {
            display: block;
        }
    }

    td.actions {
        text-align: right;
    }

    td.still {
        white-space: nowrap;
        vertical-align: top;
    }

    td.highlighted {
        @include font-weight(semibold);
    }
}

.table-wrapper {
    border-radius: $base-border-radius;
    border: 1px solid color(base-300);
    clear: both;
    overflow: auto;
}

.listing-actions {
    margin-bottom: box-model(m);
    text-align: right;
}

/**
 * Smartable widget
 */
.smart-table-outer-wrapper {
    .search-bar-wrapper {
        @extend .listing-actions;
    }

    .pagination {
        float: right;
    }

    .paginator-notes {
        @include line-height(xxm);
        margin: box-model(m);
        float: right;
    }
}

.smart-table-inner-wrapper {
    @extend .table-wrapper;
    overflow: visible;

    .badge {
        margin: 0;
    }
}

.smart-table-global-search-row {
    position: relative;
    display: inline-block;
}

.smart-table-global-search {
    max-width: $search-input-min-width;

    i {
        @include icon(ic-search);
    }

    label {
        display: none;
    }
}

.fp-typed-list {
    .search-bar {
        text-align: right;
    }

    .smart-table {
        clear: both;
    }

    .list-actions {
        display: inline-block;
        margin-right: box-model(xs);
    }

    th {
        &.sortable {
            cursor: pointer;

            i {
                @include icon(ic-arrow-down-arrow-up);

                display: inline-block;
                margin-left: box-model(xs);
                margin-top: box-model(-xxxs);
                vertical-align: middle;
            }
        }

        &.sort-asc i { @include icon(ic-chevron-up); }
        &.sort-desc i { @include icon(ic-chevron-down); }
    }

    td {
        &.erased {
            > a, > span {
                text-decoration: line-through;
                opacity: .5;
            }
        }

        &.masked {
            > a, > span {
                opacity: .5;
            }
        }

        &.has-tooltip {
            cursor: help;

            > a, > span {
                text-decoration: underline 1.5px dotted;
            }
        }
    }

    .session-tag-display-cell {
        a {
            padding: box-model(xxs) box-model(xs);
            border-radius: box-model(s);
        }
    }
}

.deleted-docs {
    td.tz {
        @include line-height(xxs);
        small {
            color: color(base-700);
        }
    }
}

.live-sessions-list {
    .title {
        width: 100%;
    }

    .type-cell {
        min-width: box-model(xs) * 16;

        i {
            @include icon-size(xs);
            margin-left: box-model(xxs);
        }
    }

    .status {
        min-width: box-model(xs) * 15;
    }

    .actions {
        min-width: box-model(xs) * 14;
    }

    .starts_at {
        min-width: box-model(xs) * 35;
    }
}

.documents {
    th.nav_type,
    th.views {
        width: box-model(h);
    }

    th.fp_last_changes_timestamp {
        width: box-model(h) * 4;
    }
}

.emailing {
    th.fp_last_changes_timestamp {
        width: box-model(h) * 4;
    }

    th.is_inactive {
        width: box-model(xs) * 10;
    }

    th.status,
    th.opens,
    th.clicks,
    th.actions {
        width: box-model(xs) * 10;
    }
}
