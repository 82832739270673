// Abstracts
@use "abstracts/index" as *;

.bulk-modal {
    .kind-external-field {
        .form-generator-field-label {
            display: none;
        }
    }

    .form-generator-row {
        padding-left: box-model(h);
        position: relative;

        .bulk-edit-checkbox {
            position: absolute;
            top: 0;
            left: box-model(xs);
        }
    }

    .modal-body {
        margin-top: box-model(m);
    }

    .time-shift-form {
        margin-top: box-model(l);

        tr {
            display: block;
            padding-bottom: box-model(xs);
        }

        td {
            padding: 0;
        }

        .radio-button {
            width: box-model(m);
        }

        .flex-cell {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: box-model(xl);
        }
    }
}
